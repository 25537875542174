import { Menu } from "antd";
import { RecursiveMenu } from "../menu/RecursiveMenu";
import {
  URL_EXPENSES,
  URL_INSTALMENTS,
  URL_INVENTORY,
  URL_LEDGERS,
  URL_LOAN_APPLICATIONS,
  URL_OTHER_INCOME,
  URL_PAYROLL,
  URL_PURCHASE_ORDERS,
  URL_REQUISITIONS,
} from "../../utils/urls";
import {
  getForm,
  itemExists,
  useGetUserPermissions,
} from "../../utils/helpers";
import { useSettings } from "../../constants/settings";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  CAN_ACCESS_PAYROLL_MODULE,
  CAN_ADD_APPLICATION,
  CAN_APPROVE_APPLICATION,
  CAN_CREATE_PAYROLL,
  CAN_PUBLIC,
  CAN_RECOMMEND_APPLICATION,
  CAN_SPECIAL_EDIT,
  CAN_SUBMIT_PURCHASE_ORDER,
  CAN_SUBMIT_RECEIVE_INVENTORY_CHECKLIST,
  CAN_VIEW_APPROVED_APPLICATIONS,
  CAN_VIEW_PURCHASE_ORDER,
  CAN_VIEW_RECOMMENDED_APPLICATIONS,
  CAN_VIEW_SUBMITTED_APPLICATIONS,
  CAN_VIEW_SUBMITTED_REGISTER,
} from "../../utils/constants";
import {
  Add,
  ArchiveOutline,
  BagAddOutline,
  BagHandleOutline,
  BarChartOutline,
  CalendarOutline,
  CardOutline,
  DocumentTextOutline,
  FileTrayFullOutline,
  GridOutline,
  LayersOutline,
  SettingsOutline,
  StorefrontOutline,
} from "@raresail/react-ionicons";

function LeftMenuItems(): any {
  const permissions = useGetUserPermissions();
  const menu: any = [
    {
      label: "Dashboard",
      to: "/",
      visible: true,
      key: "default",
      icon: <GridOutline />,
    },

    {
      label: "Applications",
      icon: <FileTrayFullOutline />,
      visible: itemExists(permissions, [
        CAN_PUBLIC,
        CAN_ADD_APPLICATION,
        CAN_APPROVE_APPLICATION,
      ]),
      children: [
        {
          label: "New Application",
          icon: <Add />,
          to: getForm("loan_application"),
          visible: itemExists(permissions, [CAN_PUBLIC, CAN_ADD_APPLICATION]),
        },
        {
          label: "Submitted",
          icon: <DocumentTextOutline />,
          to: `${URL_LOAN_APPLICATIONS}/submitted`,
          visible: itemExists(permissions, [
            CAN_PUBLIC,
            CAN_VIEW_SUBMITTED_APPLICATIONS,
            CAN_RECOMMEND_APPLICATION,
          ]),
        },
        {
          label: "Recommended",
          icon: <DocumentTextOutline />,
          to: `${URL_LOAN_APPLICATIONS}/recommended_for_approval`,
          visible: itemExists(permissions, [
            CAN_PUBLIC,
            CAN_VIEW_RECOMMENDED_APPLICATIONS,
            CAN_APPROVE_APPLICATION,
          ]),
        },
        {
          label: "Approved",
          icon: <DocumentTextOutline />,
          to: `${URL_LOAN_APPLICATIONS}/approved`,
          visible: itemExists(permissions, [
            CAN_PUBLIC,
            CAN_VIEW_APPROVED_APPLICATIONS,
            CAN_APPROVE_APPLICATION,
          ]),
        },
        {
          label: "Disbursed",
          icon: <DocumentTextOutline />,
          to: `${URL_LOAN_APPLICATIONS}/disbursed`,
          visible: itemExists(permissions, [
            CAN_PUBLIC,
            CAN_APPROVE_APPLICATION,
          ]),
        },
        {
          label: "Rejected",
          icon: <DocumentTextOutline />,
          to: `${URL_LOAN_APPLICATIONS}/rejected`,
          visible: itemExists(permissions, [
            CAN_PUBLIC,
            CAN_APPROVE_APPLICATION,
            CAN_VIEW_APPROVED_APPLICATIONS,
          ]),
        },
        {
          label: "Deffered",
          icon: <DocumentTextOutline />,
          to: `${URL_LOAN_APPLICATIONS}/deffered`,
          visible: itemExists(permissions, [
            CAN_PUBLIC,
            CAN_VIEW_RECOMMENDED_APPLICATIONS,
            CAN_RECOMMEND_APPLICATION,
          ]),
        },
      ],
    },
    {
      label: "Inventory",
      icon: <StorefrontOutline />,
      visible: itemExists(permissions, [CAN_VIEW_PURCHASE_ORDER]),
      children: [
        {
          label: "New Inventory Item",
          icon: <Add />,
          to: getForm("inventory"),
          visible: itemExists(permissions, [
            CAN_SUBMIT_RECEIVE_INVENTORY_CHECKLIST,
          ]),
        },
        {
          label: "In Stock",
          icon: <DocumentTextOutline />,
          to: `${URL_INVENTORY}`,
        },
      ],
    },
    {
      label: "Purchase Orders",
      icon: <BagHandleOutline />,
      visible: itemExists(permissions, [CAN_VIEW_SUBMITTED_REGISTER]),

      children: [
        {
          label: "New Purchase Order",
          icon: <Add />,
          to: getForm("purchase_order"),
          visible: itemExists(permissions, [CAN_SUBMIT_PURCHASE_ORDER]),
        },
        {
          label: "Submitted",
          icon: <DocumentTextOutline />,
          to: `${URL_PURCHASE_ORDERS}/submitted`,
        },
        {
          label: "Approved",
          icon: <DocumentTextOutline />,
          to: `${URL_PURCHASE_ORDERS}/approved`,
        },

        {
          label: "Rejected",
          icon: <DocumentTextOutline />,
          to: `${URL_PURCHASE_ORDERS}/rejected`,
        },
      ],
    },
    {
      label: "Expenses",
      icon: <CardOutline />,
      visible: itemExists(permissions, [CAN_SPECIAL_EDIT]),
      children: [
        {
          label: "Requisition",
          icon: <BagAddOutline />,
          // visible: itemExists(permissions, [CAN_SPECIAL_EDIT]),
          visible: false,
          children: [
            {
              label: "New Requisition",
              icon: <Add />,
              to: getForm("requisitions"),
            },
            {
              label: "Submitted",
              icon: <DocumentTextOutline />,
              to: `${URL_REQUISITIONS}/submitted`,
            },
            {
              label: "Recommended for Approval",
              icon: <DocumentTextOutline />,
              to: `${URL_REQUISITIONS}/recommended_for_approval`,
            },
            {
              label: "Approved",
              icon: <DocumentTextOutline />,
              to: `${URL_REQUISITIONS}/approved`,
            },
            {
              label: "Rejected",
              icon: <DocumentTextOutline />,
              to: `${URL_REQUISITIONS}/rejected`,
            },
          ],
        },
        {
          label: "New Expense",
          icon: <Add />,
          to: getForm("expenses"),
        },
        {
          label: "Submitted",
          icon: <DocumentTextOutline />,
          to: `${URL_EXPENSES}/submitted`,
        },
        {
          label: "Recommended for Approval",
          icon: <DocumentTextOutline />,
          to: `${URL_EXPENSES}/recommended_for_approval`,
        },
        {
          label: "Approved",
          icon: <DocumentTextOutline />,
          to: `${URL_EXPENSES}/approved`,
        },
        {
          label: "Rejected",
          icon: <DocumentTextOutline />,
          to: `${URL_EXPENSES}/rejected`,
        },
      ],
    },
    {
      label: "Other Income",
      icon: <BarChartOutline />,
      visible: itemExists(permissions, [CAN_SPECIAL_EDIT]),
      children: [
        {
          label: "New Income",
          icon: <Add />,
          to: getForm("other_income"),
        },
        {
          label: "Submitted",
          icon: <DocumentTextOutline />,
          to: `${URL_OTHER_INCOME}/submitted`,
        },
        {
          label: "Recommended for Approval",
          icon: <DocumentTextOutline />,
          to: `${URL_OTHER_INCOME}/recommended_for_approval`,
        },
        {
          label: "Approved",
          icon: <DocumentTextOutline />,
          to: `${URL_OTHER_INCOME}/approved`,
        },
        {
          label: "Rejected",
          icon: <DocumentTextOutline />,
          to: `${URL_OTHER_INCOME}/rejected`,
        },
      ],
    },
    {
      label: "Payroll",
      icon: <ArchiveOutline />,
      visible: itemExists(permissions, [
        CAN_SPECIAL_EDIT,
        CAN_ACCESS_PAYROLL_MODULE,
      ]),
      children: [
        {
          label: "New Payroll",
          icon: <Add />,
          to: getForm("payrolls"),
          visible: itemExists(permissions, [CAN_CREATE_PAYROLL]),
        },
        {
          label: "Submitted",
          icon: <DocumentTextOutline />,
          to: `${URL_PAYROLL}/submitted`,
        },
        {
          label: "Approved",
          icon: <DocumentTextOutline />,
          to: `${URL_PAYROLL}/approved`,
        },
        {
          label: "Rejected",
          icon: <DocumentTextOutline />,
          to: `${URL_PAYROLL}/rejected`,
        },
      ],
    },
    {
      label: "Instalments",
      icon: <CalendarOutline />,
      visible: permissions.includes(CAN_SPECIAL_EDIT),
      children: [
        {
          label: "Instalments",
          icon: <CalendarOutline />,
          to: `${URL_INSTALMENTS}`,
        },
      ],
    },
    {
      label: "Ledgers",
      icon: <LayersOutline />,
      visible: permissions.includes(CAN_SPECIAL_EDIT),
      children: [
        {
          label: "Add Journal Entry",
          icon: <Add />,
          to: getForm("ledgers"),
          visible: itemExists(permissions, [CAN_SPECIAL_EDIT]),
        },
        {
          label: "General Ledger",
          icon: <DocumentTextOutline />,
          to: `${URL_LEDGERS}?content_type_model=`,
        },
        {
          label: "Loan Applications",
          icon: <DocumentTextOutline />,
          to: `${URL_LEDGERS}?content_type_model=loanapplication`,
        },
        {
          label: "Purchase Orders",
          icon: <DocumentTextOutline />,
          to: `${URL_LEDGERS}?content_type_model=purchaseorder`,
        },
      ],
    },
    // SETTINGS
    {
      icon: <SettingsOutline />,
      label: "Settings",
      to: "/settings",
    },
  ];
  return menu;
}

export const LeftMenu: React.FC = () => {
  const { data } = useGlobalContext();
  const settingsItems = useSettings();
  const leftMenuItems = LeftMenuItems();
  return (
    <Menu
      className="font13 leftmenu radius6"
      mode="inline"
      defaultSelectedKeys={["default"]}
      theme="light"
    >
      {RecursiveMenu(data?.showSettings ? settingsItems : leftMenuItems)}
    </Menu>
  );
};
