import { useState } from "react";
import useAxios from "./useAxios";
import { TError } from "../../types/common";

type UploadDataParams = {
  api: string;
  params?: any;
  id?: number;
  type?: "application/json" | "multipart/form-data";
  method?: string;
};

const useSet = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<TError>({});
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<any>();
  const { set } = useAxios();

  const uploadData = (options: UploadDataParams) => {
    const { api, params, method, type, id } = options;
    const extras = {};

    // If id is passed get single instance, other fetch list
    setError({});
    setSuccess(false);
    setSubmitting(true);
    set({
      api: id ? `${api}${id}/` : api,
      loader: setSubmitting,
      errorHandler: setError,
      successHandler: (res: any) => {
        setData(res);
        setSuccess(true);
      },
      methodName: method || (id ? "PATCH" : "POST"),
      params: { ...params, ...extras },
      type,
    });
  };

  const updateErrors = (err: TError) => {
    setError(err ? { ...error, ...err } : {});
  };

  return {
    submitting,
    error,
    data,
    success,
    uploadData,
    updateErrors,
    setError,
    setSuccess,
  };
};

export default useSet;
