import { Popover } from "antd";
import { ItemWrapper } from "./Elements";
import { IconButton } from "@mui/material";
import {
  CircleOutlined,
  Create,
  MoreVert,
  Visibility,
  Delete,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { DELETE, EDIT, VIEW } from "../../utils/constants";
import useSet from "../../hooks/common/useSet";
import { useToasts } from "../../hooks/common/useToasts";

type TActionProps = {
  label: string;
  to?: string;
  icon?: any;
  onClick?: () => void;
  deleteMessage?: string;
  deleteUrl?: string;
};
type TFormActionsProps = {
  actions: TActionProps[];
};

function getIcon(label: string) {
  switch (label) {
    case VIEW:
      return <Visibility fontSize="small" />;
    case EDIT:
      return <Create fontSize="small" />;
    case DELETE:
      return <Delete fontSize="small" color="error" />;
    default:
      return <CircleOutlined sx={{ fontSize: 8, width: 20 }} />;
  }
}

const handleDelete = (
  deleteUrl: string,
  deleteMessage: string,
  onDelete: any,
) => {
  Modal.confirm({
    title: "Confirm Delete",
    content: deleteMessage,
    okText: "Yes, Delete",
    okType: "danger",
    cancelText: "Cancel",
    onOk: async () => {
      onDelete({ api: deleteUrl, method: "DELETE" });
    },
  });
};

export const ActionsMenu = (actions: any[]) => {
  const { uploadData: onDelete, success, error } = useSet();

  useToasts({
    success,
    error,
    redirect: "reload",
    successMessage: "Deleted Successfully",
  });

  return (
    <>
      {actions?.map(
        (
          {
            label,
            to,
            onClick,
            icon: Icon,
            deleteMessage = "",
            deleteUrl = "",
          }: TActionProps,
          key: number,
        ) => (
          <Link
            to={to || ""}
            onClick={
              label === DELETE
                ? () => handleDelete(deleteUrl, deleteMessage, onDelete)
                : onClick
            }
            key={key}
          >
            <ItemWrapper>
              {(Icon && <Icon fontSize="small" />) || getIcon(label)}
              {label}
            </ItemWrapper>
          </Link>
        ),
      )}
    </>
  );
};

export const FormActions = ({ actions }: TFormActionsProps) => {
  return (
    <Popover
      content={<>{ActionsMenu(actions)}</>}
      trigger="click"
      overlayStyle={{ minWidth: 150 }}
      overlayInnerStyle={{ padding: 5 }}
      placement="left"
    >
      <IconButton size="small">
        <MoreVert />
      </IconButton>
    </Popover>
  );
};
