import styled from "styled-components";
import { Menu, Popover } from "antd";
import { ActionsMenu } from "../common/FormActions";
import { alpha, IconButton } from "@mui/material";
import { primaryColor } from "../styled/Theme";
import { DocumentTextOutline } from "@raresail/react-ionicons";
export const reportSettings = [
  {
    label: "Financial Reports",
    icon: DocumentTextOutline,
    to: "/reports/balance-sheet",
  },
  {
    label: "Adhoc Reports",
    icon: DocumentTextOutline,
    to: "/reports/adhoc",
  },
];
const ReportsDropdown = () => {
  return (
    <>
      <Popover
        content={
          <>
            <Menu mode="inline" className="font13 rightmenu">
              {ActionsMenu(reportSettings)}
            </Menu>
          </>
        }
        trigger="click"
        overlayStyle={{ minWidth: 200 }}
        overlayInnerStyle={{ padding: 5 }}
        placement="bottomRight"
      >
        <AvatarWrapper className="animate">
          <IconButton
            sx={{
              bgcolor: alpha(primaryColor, 0.1),
              borderRadius: 10,
              padding: "0.5rem 1rem",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              color: "#555",
            }}
          >
            <DocumentTextOutline className="font13" />
            <span className="font13">Reports</span>
          </IconButton>
        </AvatarWrapper>
      </Popover>
    </>
  );
};

export default ReportsDropdown;

const AvatarWrapper = styled.div`
  &:hover {
    scale: 1.03;
  }
`;
