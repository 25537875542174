import { GenericObjectArrayType } from "../types/common";
export const CONST_CREDENTIALS_KEY = "DGD^#&#^#*#&#*(^GDGE#^*&*%%%";

export const colors = {
  THEME_COLOR: "#364174",
  BUTTON: "#009a44",
  WHITE: "#ffffff",
};

export const BOOLEAN_OPTIONS: GenericObjectArrayType[] = [
  { id: 0, name: "No" },
  { id: 1, name: "Yes" },
];

export const BOOLEAN_SELECTIONS: GenericObjectArrayType[] = [
  { id: false, name: "No" },
  { id: true, name: "Yes" },
];

export const PAYMENT_METHODS: GenericObjectArrayType[] = [
  { id: "cash", name: "Cash" },
  { id: "cheque", name: "Cheque" },
  { id: "yoya_pay", name: "YOYA Pay" },
  { id: "bank_transfer", name: "Bank Transfer" },
  { id: "mobile_money", name: "Mobile Money" },
  { id: "credit_card", name: "Credit Card" },
];

export const FEW_PAYMENT_METHODS: GenericObjectArrayType[] = [
  { id: "mtn_momo", name: "MTN Momo" },
  { id: "airtel_pay", name: "Airtel Pay" },
  { id: "stanbic_bank", name: "Stanbic Bank" },
];

export const MALE = "Male";
export const FEMALE = "Female";

// Document type
export const DOC_TYPE_REPORT = "report";
export const DOC_TYPE_OTHER = "other";
export const DOC_TYPE_ATTACHMENT = "attachment";

// DATE FORMATS
export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_YEAR = "YYYY";
export const DATE_TIME_FORMAT = "YYYY-MM-DDThh:mm";

// STATUS
export const PENDING = "pending";
export const SUBMITTED = "submitted";
export const RECOMMENDED_FOR_APPROVAL = "recommended_for_approval";
export const REJECTED = "rejected";
export const DELIVERED = "delivered";
export const APPROVED = "approved";
export const DEFERRED = "deferred";
export const FOWARDED = "fowarded";
export const DISBURSED = "disbursed";

// LABELS
export const VIEW = "View";
export const EDIT = "Edit";
export const DELETE = "Delete";
// MASTER DATA TYPES
export const DISTRICT = "district";
export const PARISH = "parish";
export const COUNTY = "county";
export const SUB_COUNTY = "sub_county";
export const VILLAGE = "village";

//Plurals
export const DISTRICTS = "districts";
export const COUNTIES = "counties";
export const SUB_COUNTIES = "sub_counties";
export const PARISHES = "parishes";
export const VILLAGES = "villages";

// PROFILE
export const PROFILE = "profile";
export const USERS = "users";

// Permissions
export const CAN_VIEW_SYSTEM_SETTINGS = "can_view_system_settings";
export const CAN_EDIT_SYSTEM_SETTINGS = "can_edit_system_settings";
export const CAN_EDIT_USERS = "can_edit_users";
export const CAN_ADD_LOAN_USER = "add_yoyaloanuser";
export const CAN_CHANGE_LOAN_USER = "change_yoyaloanuser";
export const CAN_DELETE_LOAN_USER = "delete_yoyaloanuser";
export const CAN_VIEW_LOAN_USER = "view_yoyaloanuser";
export const CAN_VIEW_REPORTS = "can_view_reports";
export const CAN_PUBLIC = "can_public";

// Application permissions
export const CAN_VIEW_SUBMITTED_APPLICATIONS =
  "can_view_submitted_applications";
export const CAN_VIEW_APPROVED_APPLICATIONS = "can_view_approved_applications";
export const CAN_VIEW_RECOMMENDED_APPLICATIONS =
  "can_view_recommended_applications";
export const CAN_APPROVE_APPLICATION = "can_approve_application";
export const CAN_REJECT_APPLICATION = "can_reject_application";
export const CAN_DEFER_APPLICATION = "can_defer_application";
export const CAN_RECOMMEND_APPLICATION = "can_recommend_application";
export const CAN_REVIEW_APPLICATION = "can_review_application";
export const CAN_ADD_APPLICATION = "can_add_application";
export const CAN_SPECIAL_EDIT = "can_special_edit";
export const CAN_MAKE_PAYMENTS = "can_make_payments";

// Organisation permissions
export const CAN_ADD_ORGANISATION = "can_add_organisation";

// Payroll permissions
export const CAN_CREATE_PAYROLL = "can_process_payroll";
export const CAN_REVIEW_PAYROLL = "can_review_payroll";
export const CAN_APPROVE_PAYROLL = "can_approve_payroll";
export const CAN_ACCESS_PAYROLL_MODULE = "can_access_payroll_module";
export const CAN_EDIT_PAYROLL = "can_update_payroll_data";
export const CAN_ADD_INVENTORY_CHECKLIST = "can_add_inventory_checklist";

// inventory permissions
export const CAN_SUBMIT_PURCHASE_ORDER = "can_submit_purchase_order";
export const CAN_VIEW_PURCHASE_ORDER = "can_view_submitted_register";
export const CAN_APPROVE_PURCHASE_ORDER = "can_approve_purchase_order";
export const CAN_EDIT_PURCHASE_ORDER = "can_review_submitted_purchase_order";
export const CAN_VIEW_SUBMITTED_REGISTER = "can_view_submitted_register";
export const CAN_SUBMIT_RECEIVE_INVENTORY_CHECKLIST =
  "can_submit_receive_inventory_checklist";
export const CAN_EDIT_RECEIVED_INVENTORY_CHECKLIST =
  "can_review_received_inventory_checklist";

// Purchase order permissions
export const CAN_ADD_PRODUCT_TO_PURCHASE_ORDERS = "can_add_product_to_orders";

export const APPLICATIONS_ACTIONS = [
  CAN_APPROVE_APPLICATION,
  CAN_REJECT_APPLICATION,
  CAN_RECOMMEND_APPLICATION,
  CAN_REVIEW_APPLICATION,
];

// TYPES
export const SELECT = "select";
export const MODAL = "modal";
export const TEXT = "text";

// SOURCES
export const LOAN_APPLICATION = "loan_application";

export const FORM_DATA = "multipart/form-data";

// CONFIGURABLES
export const MOTORCYCLE_TYPE = "motorcycle-type";
export const VEHICLE_TYPE = "vehicle-type";
export const CATEGORY_INVENTORY_TYPE = "inventory-category";
export const INCOME_TYPE = "income-type";
export const EXPENSE_TYPE = "expense-type";
// FILE EXTENSIONS
export const FILE_TYPE_DOC = "doc";
export const FILE_TYPE_DOCX = "docx";
export const FILE_TYPE_JPG = "jpg";
export const FILE_TYPE_JPEG = "jpeg";
export const FILE_TYPE_PNG = "png";
export const FILE_TYPE_PDF = "pdf";

// data view
export const DEFAULT_EXCLUDED = [
  "id",
  "declaration",
  "created_at",
  "updated_at",
  "deleted",
];

// Salary component
export const BASE_SALARY = "base_salary";
export const ALLOWANCE = "allowance";
export const BONUS = "bonus";

// Adhoc Reports
const TODAY = "today";
const YESTERDAY = "yesterday";
const THIS_WEEK = "this_week";
const LAST_WEEK = "last_week";
const THIS_MONTH = "this_month";
const LAST_MONTH = "last_month";
const THIS_YEAR = "this_year";
const LAST_YEAR = "last_year";
const THIS_FY = "this_fy";
const LAST_FY = "last_fy";

export const START_DATE_FILTER = "created_at_min_date";
export const END_DATE_FILTER = "created_at_max_date";

export const preFilters = [
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_YEAR,
  THIS_FY,
  LAST_FY,
];

export const CONST_PAYMENTS = "payments";
export const LOAN_APPLICATIONS = "loan-applications";
export const PURCHASE_ORDERS = "purchase-orders";

export const CONST_MODULES = [
  { value: CONST_PAYMENTS, label: "Payments" },
  {
    value: LOAN_APPLICATIONS,
    label: "Loan Applications",
  },
  {
    value: PURCHASE_ORDERS,
    label: "Purchase Orders",
  },
];

export const APP_NAME = "Tayari";
export const APP_ORGANIZATION = "Tayari";
// export const APP_NAME = "YOYA Loans";
